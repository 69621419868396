// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js or *_controller.ts.

import { Application } from 'stimulus';

// TODO: This manual import should be a temporal solution because esbuild does not know about
// context like webpack
import Dropdown from './dropdown_controller';
import CheckboxToggleController from './checkbox_toggle_controller';
import NestedFormController from './nested_form_controller';
import RadioToggleController from './radio_toggle_controller';
import VideoPlayerController from './video_player_controller';
import ModalController from './modal_controller';
import AlertController from './alert_controller';
import PositionController from './position_controller';
import PlaylistBulkController from './playlist_bulk_controller';
import ResetFormController from './reset_form_controller';
import StripeController from './stripe_controller';
import AutoSubmitForm from './auto_submit_form';
import CopyController from './copy_controller';
import QuoteController from './quote_controller';
import TooltipController from './tooltip_controller';
import SubscriptionController from './subscription_controller';
import UploadzoneController from './uploadzone_controller';
import RefreshController from './refresh_controller';

const application = Application.start();

application.register('checkbox-toggle', CheckboxToggleController);
application.register('nested-form', NestedFormController);
application.register('radio-toggle', RadioToggleController);
application.register('video-player', VideoPlayerController);
application.register('modal', ModalController);
application.register('alert', AlertController);
application.register('position', PositionController);
application.register('playlist-bulk', PlaylistBulkController);
application.register('reset-form', ResetFormController);
application.register('stripe', StripeController);
application.register('dropdown', Dropdown);
application.register('auto-submit-form', AutoSubmitForm);
application.register('copy', CopyController);
application.register('quote', QuoteController);
application.register('tooltip', TooltipController);
application.register('subscription', SubscriptionController);
application.register('uploadzone', UploadzoneController);
application.register('refresh', RefreshController);
