import { Controller } from 'stimulus';
import { Turbo } from '@hotwired/turbo-rails';

window.Turbo = Turbo;

export default class extends Controller {
  static values = {
    waiting: String, // Example: /subscriptions/refresh_thanks
    success: String, // Example: /subscriptions/thanks
  }

  connect() {
    this.interval = setInterval(() => this.refreshFrame(), 2000); // Poll every 2 seconds
  }

  disconnect() {
    clearInterval(this.interval); // Stop polling when the frame is removed
  }

  async refreshFrame() {
    try {
      const response = await fetch(this.waitingValue, {
        headers: { Accept: 'text/vnd.turbo-stream.html' },
      });
      if (response.ok) {
        const html = await response.text();
        this.element.innerHTML = html;
        clearInterval(this.interval); // Stop polling if data is ready
        Turbo.visit(this.successValue);
      }
    } catch (error) {
      console.error(this.waitingValue);
      console.error(this.successValue);
      console.error('Error refreshing subscription details:', error);
    }
  }
}
